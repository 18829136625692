import '@fontsource/titillium-web';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Divider, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import Flag from '../assets/images/flag.png';

const Home = ({
    scrollToSection,
}: {
    // eslint-disable-next-line no-unused-vars
    scrollToSection: (section: string) => void;
}) => (
    <Box
        id="home"
        minHeight="100vh"
        pt={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
    >
        <img
            src={Flag}
            alt="Flag"
            style={{
                height: '30vh',
                width: 'auto',
                marginBottom: '5vh',
            }}
        />
        <Typography
            variant="h2"
            sx={{ fontSize: { xs: '2rem', sm: '3.75rem' } }}
        >
            European Gasshuku 2025
        </Typography>
        <Typography
            variant="h3"
            color="primary"
            sx={{ fontSize: { xs: '1.5rem', sm: '3rem' } }}
        >
            May 2-4, 2025 - Brussels, Belgium
        </Typography>
        <Box sx={{ width: '20%', my: 4 }}>
            <Divider />
        </Box>
        <Button
            variant="contained"
            onClick={() => scrollToSection('register')}
            sx={{ marginBottom: '10vh' }}
        >
            Register
        </Button>
        <IconButton
            color="primary"
            onClick={() => scrollToSection('infos')}
            sx={{
                marginBottom: '10vh',
                backgroundColor: 'primary.main',
                color: 'white',
                '&:hover': {
                    backgroundColor: 'primary.dark',
                },
                width: '48px',
                height: '48px',
            }}
        >
            <KeyboardArrowDownIcon />
        </IconButton>
    </Box>
);

export default Home;
