import * as Yup from 'yup';

export const registerSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    birthdate: Yup.date().required('Birthdate is required'),
    city: Yup.string().required('City is required'),
    country: Yup.string().required('Country is required'),
    grade: Yup.string().required('Grade is required'),
    sensei_name: Yup.string().required('Sensei name is required'),
    sayonaraParty: Yup.number(),
    tshirtSize: Yup.string(),
    danGrading: Yup.boolean(),
    participationType: Yup.string().required(
        'Please select a participation type',
    ),
    totalPrice: Yup.number(),
});
