import '@fontsource/titillium-web';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Box,
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Theme as MuiTheme, useTheme } from '@mui/material/styles';
import React from 'react';

const styles = {
    container: {
        width: '100%',
        overflowX: 'auto',
        pt: 2,
        minHeight: 'calc(100vh - 56px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableContainer: {
        mb: 1,
    },
    cell: {
        fontSize: '0.8rem',
    },
    dayCell: {
        fontWeight: 'bold',
        fontSize: { xs: '0.8rem', sm: '1.2rem' },
        color: 'primary.main',
        textAlign: { xs: 'left', sm: 'center' },
    },
    headerCell: {
        fontWeight: 'bold',
        fontSize: '0.8rem',
    },
    iconButton: {
        backgroundColor: 'primary.main',
        color: 'white',
        marginBottom: '5vh',
        '&:hover': {
            backgroundColor: 'primary.dark',
        },
        width: '48px',
        height: '48px',
    },
    scrollIndicator: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: '20px',
        background:
            'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    scrollIcon: {
        fontSize: '1.5rem',
        color: 'primary.main',
        animation: 'pulse 1.5s infinite',
    },
};

const Schedule = ({
    scrollToSection,
}: {
    // eslint-disable-next-line no-unused-vars
    scrollToSection: (section: string) => void;
}) => {
    const scheduleData = [
        {
            day: 'Friday',
            data: [
                {
                    time: '16.00 - 16.45',
                    kids: 'Sara',
                    kyu10to7: '',
                    kyu6to4: '',
                    kyu3to1: '',
                    shodan: 'Junbi Undo - Bakkies',
                    sandan: '',
                    godan: '',
                },
                {
                    time: '17.00 - 17.45',
                    kids: 'Igor',
                    kyu10to7: 'Vogel',
                    kyu6to4: 'Curinckx',
                    kyu3to1: 'Panasiuk',
                    shodan: 'Kuramoto',
                    sandan: 'Andrews',
                    godan: 'Bakkies',
                },
                {
                    time: '18.00 - 18.45',
                    kids: 'Tomasz',
                    kyu10to7: 'Panasiuk',
                    kyu6to4: 'Vogel',
                    kyu3to1: 'Curinckx',
                    shodan: 'Andrews',
                    sandan: 'Bakkies',
                    godan: 'Kuramoto',
                },
                {
                    time: '19.00 - 19.45',
                    kids: 'No class',
                    kyu10to7: 'Curinckx',
                    kyu6to4: 'Kuramoto',
                    kyu3to1: 'Vogel',
                    shodan: 'Bakkies',
                    sandan: 'Panasiuk',
                    godan: 'Andrews',
                },
            ],
        },
        {
            day: 'Saturday',
            data: [
                {
                    time: '09.00 - 09.45',
                    kids: 'Marc',
                    kyu10to7: '',
                    kyu6to4: '',
                    kyu3to1: '',
                    shodan: 'Junbi Undo - Bakkies',
                    sandan: '',
                    godan: '',
                },
                {
                    time: '10.00 - 10.45',
                    kids: 'Svetlana',
                    kyu10to7: 'Andrews',
                    kyu6to4: 'Curinckx',
                    kyu3to1: 'Vogel',
                    shodan: 'Bakkies',
                    sandan: 'Kuramoto',
                    godan: 'Panasiuk',
                },
                {
                    time: '11.00 - 11.45',
                    kids: 'Tomasz',
                    kyu10to7: 'Vogel',
                    kyu6to4: 'Panasiuk',
                    kyu3to1: 'Curinckx',
                    shodan: 'Kuramoto',
                    sandan: 'Bakkies',
                    godan: 'Andrews',
                },
                {
                    time: '12.00 - 12.45',
                    kids: 'Igor',
                    kyu10to7: 'Curinckx',
                    kyu6to4: 'Vogel',
                    kyu3to1: 'Kuramoto',
                    shodan: 'Panasiuk',
                    sandan: 'Andrews',
                    godan: 'Bakkies',
                },
                {
                    time: '13.00 - 14.00',
                    kids: '',
                    kyu10to7: '',
                    kyu6to4: '',
                    kyu3to1: '',
                    shodan: 'photo session',
                    sandan: '',
                    godan: '',
                },
                {
                    time: '14.00 - ...',
                    kids: '',
                    kyu10to7: '',
                    kyu6to4: '',
                    kyu3to1: '',
                    shodan: 'Examen session',
                    sandan: '',
                    godan: '',
                },
            ],
        },
        {
            day: 'Sunday',
            data: [
                {
                    time: '09.00 - 09.45',
                    kids: 'Svetlana',
                    kyu10to7: '',
                    kyu6to4: '',
                    kyu3to1: '',
                    shodan: 'Junbi Undo - Bakkies',
                    sandan: '',
                    godan: '',
                },
                {
                    time: '10.00 - 10.45',
                    kids: 'Igor',
                    kyu10to7: 'Vogel',
                    kyu6to4: 'Kuramoto',
                    kyu3to1: 'Andrews',
                    shodan: 'Curinckx',
                    sandan: 'Panasiuk',
                    godan: 'Bakkies',
                },
                {
                    time: '11.00 - 11.45',
                    kids: 'Sara',
                    kyu10to7: 'Kuramoto',
                    kyu6to4: 'Andrews',
                    kyu3to1: 'Curinckx',
                    shodan: 'Vogel',
                    sandan: 'Bakkies',
                    godan: 'Panasiuk',
                },
                {
                    time: '12.00 - 12.45',
                    kids: 'Marc',
                    kyu10to7: 'Curinckx',
                    kyu6to4: 'Panasiuk',
                    kyu3to1: 'Vogel',
                    shodan: 'Bakkies',
                    sandan: 'Andrews',
                    godan: 'Kuramoto',
                },
                {
                    time: '13.00 - 13.30',
                    kids: '',
                    kyu10to7: '',
                    kyu6to4: '',
                    kyu3to1: '',
                    shodan: 'All togheter - Bakkies',
                    sandan: '',
                    godan: '',
                },
            ],
        },
    ];
    const theme: MuiTheme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box id="schedule">
            <Container maxWidth="lg" sx={styles.container}>
                <Typography variant="h5" gutterBottom>
                    Schedule
                </Typography>
                {scheduleData.map(daySchedule => (
                    <Box
                        key={daySchedule.day}
                        sx={{ position: 'relative', width: '100%' }}
                    >
                        <TableContainer
                            component={Paper}
                            sx={styles.tableContainer}
                            elevation={3}
                        >
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            align="center"
                                            sx={styles.dayCell}
                                        >
                                            {daySchedule.day}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '120px',
                                            }}
                                        >
                                            Time
                                        </TableCell>
                                        <TableCell sx={styles.headerCell}>
                                            Kids
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '110px',
                                            }}
                                        >
                                            10 kyu - 7 kyu
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '110px',
                                            }}
                                        >
                                            6 kyu - 4 kyu
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '110px',
                                            }}
                                        >
                                            3 kyu - 1 kyu
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '150px',
                                            }}
                                        >
                                            Shodan - Nidan
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '130px',
                                            }}
                                        >
                                            Sandan - Yondan
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                ...styles.headerCell,
                                                minWidth: '135px',
                                            }}
                                        >
                                            Godan and above
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {daySchedule.data.map(row => (
                                        <TableRow key={row.time}>
                                            <TableCell sx={styles.cell}>
                                                {row.time}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.kids}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.kyu10to7}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.kyu6to4}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.kyu3to1}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.shodan}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.sandan}
                                            </TableCell>
                                            <TableCell sx={styles.cell}>
                                                {row.godan}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {isSmallScreen && (
                            <Box sx={styles.scrollIndicator}>
                                <KeyboardArrowRightIcon
                                    sx={styles.scrollIcon}
                                />
                            </Box>
                        )}
                    </Box>
                ))}
                <IconButton
                    color="primary"
                    onClick={() => scrollToSection('register')}
                    sx={styles.iconButton}
                >
                    <KeyboardArrowDownIcon />
                </IconButton>
            </Container>
        </Box>
    );
};

export default Schedule;
