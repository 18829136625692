/* eslint-disable no-console */
import { Moment } from 'moment';
import { useMutation } from 'react-query';
import { API_URL } from '../constants';

interface MutationResponse {
    success: boolean;
    message: string;
}

export interface RegisterValues {
    firstName: string;
    lastName: string;
    email: string;
    birthdate: Moment | null | undefined;
    city: string;
    country: string;
    grade: string;
    sensei_name: string;
    sayonaraParty?: number;
    tshirtSize?: string;
    tshirtQuantity?: number;
    danGrading: boolean;
    participationType: string;
    totalPrice: number;
}

const useRegister = (
    // eslint-disable-next-line no-unused-vars
    onRegistrationComplete: (success: boolean, message: string) => void,
) =>
    useMutation<MutationResponse, Error, RegisterValues>(
        (values: RegisterValues) =>
            fetch(API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain;charset=utf-8',
                },
                body: JSON.stringify(values),
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            }),
        {
            onSuccess: (data: any) => {
                if (data.statusCode === 201) {
                    console.log('Submission successful', data.data.message);
                    onRegistrationComplete(true, 'Registration successful');
                } else if (data.statusCode === 409) {
                    console.log('Registration failed: Email already exists');
                    onRegistrationComplete(false, 'Email already exists');
                } else {
                    console.log('Submission failed', data.data.message);
                    onRegistrationComplete(false, data.data.message);
                }
            },
            onError: error => {
                console.error('Submission error:', error);
            },
        },
    );

export default useRegister;
