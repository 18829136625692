import Masonry from '@mui/lab/Masonry';
import {
    Box,
    Container,
    Link,
    List,
    ListItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import {
    ADULT_EARLY_PRICE,
    ADULT_REGULAR_PRICE,
    CHILDREN_EARLY_PRICE,
    CHILDREN_REGULAR_PRICE,
    EARLY_BIRD_DATE,
    SAYONARA_PRICE_PER_PERSON,
} from '../constants';

const infoData = [
    {
        id: 'senseis',
        title: 'Senseis',
        content: [
            <React.Fragment key="senseis">
                <List dense disablePadding>
                    {[
                        {
                            name: 'L. Bakkies',
                            rank: '9th Dan',
                            country: 'South Africa',
                        },
                        {
                            name: 'M. Kuramoto',
                            rank: '8th Dan',
                            country: 'Okinawa',
                        },
                        {
                            name: 'G. Andrews',
                            rank: '8th Dan',
                            country: 'England',
                        },
                        {
                            name: 'V. Panasiuk',
                            rank: '8th Dan',
                            country: 'Moldova',
                        },
                        {
                            name: 'P. Curinckx',
                            rank: '7th Dan',
                            country: 'Belgium',
                        },
                        {
                            name: 'R. Vogel',
                            rank: '7th Dan',
                            country: 'Austria',
                        },
                    ].map(sensei => (
                        <ListItem
                            key={sensei.name}
                            sx={{
                                py: 0.5,
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ ml: -2, flexGrow: 1 }}
                            >
                                <strong>Sensei {sensei.name}</strong>
                            </Typography>
                            <Typography
                                variant="caption"
                                color="text.secondary"
                            >
                                {sensei.rank} • {sensei.country}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </React.Fragment>,
        ],
    },
    {
        id: 'prices',
        title: 'Prices',
        content: [
            <React.Fragment key="prices">
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography variant="subtitle1">
                        Early registration:
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                    }}
                >
                    <Typography variant="body2">Children:</Typography>
                    <Typography variant="body2">
                        {CHILDREN_EARLY_PRICE}€
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                    }}
                >
                    <Typography variant="body2">Adults:</Typography>
                    <Typography variant="body2">
                        {ADULT_EARLY_PRICE}€
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography variant="subtitle1">
                        After {EARLY_BIRD_DATE}:
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 1,
                    }}
                >
                    <Typography variant="body2">Children:</Typography>
                    <Typography variant="body2">
                        {CHILDREN_REGULAR_PRICE}€
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        mb: 2,
                    }}
                >
                    <Typography variant="body2">Adults:</Typography>
                    <Typography variant="body2">
                        {ADULT_REGULAR_PRICE}€
                    </Typography>
                </Box>
            </React.Fragment>,
        ],
    },
    {
        id: 'address',
        title: 'Dojo address',
        content: [
            <React.Fragment key="address">
                <Typography>
                    Gymnasium : boulevard des Invalides 214/216 - 1160 Auderghem
                    (Brussels)
                </Typography>
                <Typography>
                    50m from the Underground station &quot;Delta&quot;
                </Typography>
                <Box sx={{ width: '100%', height: '200px', mt: 2 }}>
                    <iframe
                        title="Dojo location map"
                        // eslint-disable-next-line max-len
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.8089872233454!2d4.4308611!3d50.8149444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3db5e2d37d1d9%3A0x2d9bf5f1d8a0c3a7!2sBoulevard%20des%20Invalides%20214%2C%201160%20Auderghem%2C%20Belgium!5e0!3m2!1sen!2sus!4v1653395698974!5m2!1sen!2sus"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Box>
            </React.Fragment>,
        ],
    },
    {
        id: 'airport',
        title: 'Aéroport',
        content: [
            <React.Fragment key="airport">
                <Typography>Brussels Airport International (closer)</Typography>
                <Typography>Brussels South – Charleroi (65 km)</Typography>
            </React.Fragment>,
        ],
    },
    {
        id: 'sayonara',
        title: 'Sayonara',
        content: [
            <React.Fragment key="sayonara">
                <Typography>
                    Cocktail from 7:00 PM with the rhythm of the band &ldquo;The
                    Slik Beavers&rdquo;.
                </Typography>
                <Typography>
                    <strong>Limited to 100 people.</strong>
                </Typography>
                <Typography>
                    Saturday, May <strong>3rd from 7:00 PM</strong> at the{' '}
                    <strong>Panorama Hotel.</strong>
                </Typography>
                <Typography>
                    <strong>{SAYONARA_PRICE_PER_PERSON}€</strong> per person
                </Typography>
            </React.Fragment>,
        ],
    },
    {
        id: 'contact',
        title: 'Contact',
        content: [
            <React.Fragment key="contact">
                <Typography>
                    Email:{' '}
                    <Link
                        href="mailto:info@gojuryu.be"
                        sx={{
                            color: 'primary.main',
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline' },
                        }}
                    >
                        info@gojuryu.be
                    </Link>
                </Typography>
                <Typography>
                    Phone:{' '}
                    <Link
                        href="tel:+32473233936"
                        sx={{
                            color: 'primary.main',
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline' },
                        }}
                    >
                        +32 473 23 39 36
                    </Link>
                </Typography>
            </React.Fragment>,
        ],
    },
    {
        id: 'bank',
        title: 'Bank details',
        content: [
            <React.Fragment key="contact">
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Bank name
                            </TableCell>
                            <TableCell>FINTRO</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Bank address
                            </TableCell>
                            <TableCell>
                                Montagne du Parc 3 1000 Brussels (Belgium)
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                IBAN
                            </TableCell>
                            <TableCell>BE76 1430 6360 2395</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                BIC
                            </TableCell>
                            <TableCell>GEBA BE BB</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Reference
                            </TableCell>
                            <TableCell>full name + grade</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </React.Fragment>,
        ],
    },
];

const hotelData = [
    {
        id: 'hotel-panorama',
        title: 'Hotel Panorama',
        content: [
            <React.Fragment key="hotel-panorama">
                <Typography>
                    <strong>
                        Offers a special price when you mention that you are
                        coming for Karate.
                    </strong>
                </Typography>
                <Typography>Hengstenberg 77, 3090 Overijse</Typography>
                <Typography>
                    <a
                        href="https://www.hotelpanorama.be/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Web site
                    </a>
                </Typography>
                <Box sx={{ width: '100%', height: '200px', mt: 2, mb: 4 }}>
                    <iframe
                        title="Hotel Panorama map"
                        // eslint-disable-next-line max-len
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.5506427785387!2d4.498605699999999!3d50.7774736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3d9f457e6a34b%3A0x9c2fa3ec2ba6f021!2sHotel%20Panorama!5e0!3m2!1sen!2sus!4v1653395698974!5m2!1sen!2sus"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Box>
            </React.Fragment>,
        ],
    },
    {
        id: 'hotel-trois-fontaines',
        title: 'Auberge des trois fontaines',
        content: [
            <React.Fragment key="hotel-trois-fontaines">
                <Typography>Chaussée de Wavre 2057, 1160 Auderghem</Typography>
                <Typography>
                    <a
                        href="https://www.auberge3fontaines.be/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Web site
                    </a>
                </Typography>
                <Box sx={{ width: '100%', height: '200px', mt: 2, mb: 4 }}>
                    <iframe
                        title="Auberge des trois fontaines map"
                        // eslint-disable-next-line max-len
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.5506427785387!2d4.4410751!3d50.8095009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3db045d844a7f%3A0xbd7bb6c4452957da!2sAuberge+Des+3+Fontaines!5e0!3m2!1sen!2sbe!4v1653395698974!5m2!1sen!2sbe"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Box>
            </React.Fragment>,
        ],
    },
    {
        id: 'hotel-soret',
        title: 'Hotel Soret',
        content: [
            <React.Fragment key="hotel-soret">
                <Typography>Kapucijnendreef 1 - 3090 Overijse</Typography>
                <Typography>
                    <a
                        href="https://hotel-soret.be/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Web site
                    </a>
                </Typography>
                <Box sx={{ width: '100%', height: '200px', mt: 2, mb: 4 }}>
                    <iframe
                        title="Hotel Soret map"
                        // eslint-disable-next-line max-len
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.0453075075787!2d4.480361715771287!3d50.79371675952366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3da320296998f%3A0xd5e561663497bc7c!2sHotel%20Soret!5e0!3m2!1sen!2sbe!4v1684847887409!5m2!1sen!2sbe"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Box>
            </React.Fragment>,
        ],
    },
    {
        id: 'hotel-adagio',
        title: 'Adagio Aparthotel Delta (Brussels)',
        content: [
            <React.Fragment key="hotel-adagio">
                <Typography>500m from the Dojo</Typography>
                <Typography>
                    Boulevard du Triomphe 207, 1160 Bruxelles
                </Typography>
                <Typography>
                    <a
                        href="https://www.adagio-city.com/fr/hotel-B5M6-aparthotel-adagio-access-brussels-delta/index.shtml"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Web site
                    </a>
                </Typography>
                <Box sx={{ width: '100%', height: '200px', mt: 2, mb: 4 }}>
                    <iframe
                        title="Adagio Aparthotel Delta map"
                        // eslint-disable-next-line max-len
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.698630139521!2d4.3957593!3d50.8159729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c3c5c55acc8fb7%3A0xe9ff8b9527dc385d!2sAparthotel+Adagio+Access+Brussels+Delta!5e0!3m2!1sen!2sbe!4v1653395698974!5m2!1sen!2sbe"
                        width="100%"
                        height="100%"
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </Box>
            </React.Fragment>,
        ],
    },
];

interface InfoItem {
    id: string;
    title: string;
    content: ReactNode[];
}

const Infos = () => (
    <Box
        id="infos"
        sx={{
            height: 'auto',
            padding: 2,
            overflowY: 'auto',
        }}
    >
        <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
            Informations
        </Typography>
        <Container maxWidth="lg" sx={{ p: 0 }}>
            <Masonry
                columns={{ xs: 1, sm: 2, md: 3 }}
                spacing={{ xs: 0, sm: 2, md: 2 }}
                sx={{ margin: { xs: 0, sm: 'auto' } }}
            >
                {infoData.map((info: InfoItem) => (
                    <div key={info.id}>
                        <Paper
                            elevation={3}
                            sx={{ p: 2, mb: { xs: 2, sm: 0 } }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                color="primary"
                            >
                                {info.title}
                            </Typography>
                            {info.content}
                        </Paper>
                    </div>
                ))}
            </Masonry>
            <Typography
                variant="h5"
                gutterBottom
                color="primary"
                sx={{ mt: 4, mb: 2 }}
            >
                Accomodations
            </Typography>
            <Masonry
                columns={{ xs: 1, sm: 2, md: 3 }}
                spacing={{ xs: 0, sm: 2, md: 2 }}
                sx={{ margin: { xs: 0, sm: 'auto' } }}
            >
                {hotelData.map((hotel: InfoItem) => (
                    <div key={hotel.id}>
                        <Paper
                            elevation={3}
                            sx={{ p: 2, mb: { xs: 2, sm: 0 } }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                color="primary"
                            >
                                {hotel.title}
                            </Typography>
                            {hotel.content}
                        </Paper>
                    </div>
                ))}
            </Masonry>
        </Container>
    </Box>
);

export default Infos;
