import '@fontsource/titillium-web';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Background from './assets/images/background.png';
import Logo from './assets/images/logo.png';
import Home from './components/Home';
import Infos from './components/Infos';
import Register from './components/Register';
import Schedule from './components/Schedule';

// ... existing theme creation ...

const sections = [
    { id: 'home', label: 'Home' },
    { id: 'infos', label: 'Infos' },
    { id: 'schedule', label: 'Schedule' },
    { id: 'register', label: 'Register' },
    // Add more sections as needed
];

const theme = createTheme({
    palette: {
        primary: {
            main: '#E00536',
        },
        secondary: {
            main: '#bf9515',
        },
        background: {
            default: '#f5f5f5', // Light grey color
        },
    },
    typography: {
        fontFamily: '"Titillium Web", sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    scrollbarGutter: 'stable',
                },
                body: {
                    padding: '0 !important',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                        width: '12px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#E00536',
                        borderRadius: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
    },
});

const styles = {
    backgroundBox: {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: -1,
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    logo: {
        height: '50px',
        width: 'auto',
    },
    navBox: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    navButton: (isActive: boolean) => ({
        backgroundColor: isActive ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        },
    }),
};

const App = () => {
    const queryClient = new QueryClient();
    const appBarRef = useRef<HTMLDivElement>(null);
    const [activeSection, setActiveSection] = useState('home');

    const scrollToSection = useCallback(
        (sectionId: string) => {
            const element = document.getElementById(sectionId);
            if (element && appBarRef.current) {
                const appBarHeight = appBarRef.current.clientHeight;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition =
                    elementPosition + window.pageYOffset - appBarHeight;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
                setActiveSection(sectionId);
            }
        },
        [appBarRef],
    );

    useEffect(() => {
        const handleScroll = () => {
            const windowHeight = window.innerHeight;
            const offset = 100;

            const newActiveSection = sections.find(section => {
                const element = document.getElementById(section.id);
                if (element) {
                    const { top, bottom } = element.getBoundingClientRect();
                    return top <= offset && bottom >= windowHeight - offset;
                }
                return false;
            });

            if (newActiveSection) {
                setActiveSection(newActiveSection.id);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Box sx={styles.backgroundBox} />
                <CssBaseline />
                <AppBar position="fixed" ref={appBarRef}>
                    <Toolbar sx={styles.toolbar}>
                        <Box
                            sx={{
                                height: { xs: '30px', sm: '40px' },
                                width: 'auto',
                            }}
                        >
                            <a
                                href="https://www.gojuryu.be/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={Logo}
                                    alt="Logo"
                                    style={{
                                        ...styles.logo,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                    }}
                                />
                            </a>
                        </Box>
                        <Box sx={styles.navBox}>
                            {sections.map(section => (
                                <Button
                                    key={section.id}
                                    color="inherit"
                                    onClick={() => scrollToSection(section.id)}
                                    sx={{
                                        ...styles.navButton(
                                            activeSection === section.id,
                                        ),
                                        fontSize: { xs: '0.8rem', sm: '1rem' }, // Smaller font on small screens
                                        padding: {
                                            xs: '6px 8px',
                                            sm: '8px 16px',
                                        }, // Smaller padding on small screens
                                    }}
                                >
                                    {section.label}
                                </Button>
                            ))}
                        </Box>
                    </Toolbar>
                </AppBar>
                <Toolbar />
                <Home scrollToSection={scrollToSection} />
                <Infos />
                <Schedule scrollToSection={scrollToSection} />
                <Register />
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;
