export const API_URL =
    'https://script.google.com/macros/s/AKfycbyLyb7wqXgUNWzSlWe1A75ABS7GAEzgrrBtMRNFBA1U_CfAZtmyZzIp5se36G8d4y-NkA/exec';

export const SAYONARA_PRICE_PER_PERSON = 50;
export const EARLY_BIRD_DATE = '2025-02-01';
export const TSHIRT_PRICE = 20;
export const ADULT_EARLY_PRICE = 135;
export const ADULT_REGULAR_PRICE = 150;
export const CHILDREN_EARLY_PRICE = 50;
export const CHILDREN_REGULAR_PRICE = 70;
